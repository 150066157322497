import React from "react";
import { Link, useLocation } from "react-router-dom";
import useLocales from "../locales/useLocales";
import ApplyForm from "./ApplyForm";

export default function Navbar() {
  const { pathname } = useLocation();
  const [open, setopen] = React.useState(false);
  const [openForm, setOpenForm] = React.useState(false);
  const { onChangeLang, translate, currentLang } = useLocales();

  const isActive =
    pathname === "/english-in-uk" ||
    pathname === "/english-in-usa" ||
    pathname === "/foundation-in-uk" ||
    pathname === "/foundation-in-usa" ||
    pathname === "/undergraduate-in-uk" ||
    pathname === "/undergraduate-in-usa" ||
    pathname === "/master-in-uk" ||
    pathname === "/master-in-usa" ||
    pathname === "/phd";

  React.useEffect(() => {
    if (currentLang.value === "ar") {
      document.dir = "rtl";
    } else {
      document.dir = "ltl";
    }
  }, [currentLang]);
 
  return (
    <>
      {openForm && <ApplyForm onClose={() => setOpenForm(false)} />}
      <header className="site_header site_header_1">
        <div className="container">
          <div className="row align-items-center">
            <div className="col col-lg-3 col-5">
              <div className="site_logo">
                <Link className="site_link" to="/">
                  <img src="assets/images/logo/ukud-logo.webp" alt="UKUK" />
                  <p
                    className="mb-0 d-none d-md-block text-center"
                    style={{ color: "#0f1148" }}
                  >
                    {translate('International Education Consultancy')}
                  </p>
                </Link>
              </div>
            </div>
            <div className="col col-lg-6 col-2">
              <nav className="main_menu navbar navbar-expand-lg">
                <div
                  className={`main_menu_inner collapse navbar-collapse justify-content-center ${
                    open ? "show" : ""
                  }`}
                  id="main_menu_dropdown"
                >
                  <ul className="main_menu_list unordered_list_center">
                    <li className={pathname === "/" ? "active" : ""}>
                      <Link className="nav-link" to="/" id="home_submenu">
                        {translate("Home")}
                      </Link>
                    </li>
                    <li
                      className={`dropdown ${
                        pathname === "/about-us" || pathname === "/gallery" || pathname === "/meet-the-team"
                          ? "active"
                          : ""
                      }`}
                    >
                      <Link
                        className={`nav-link `}
                        to="/about-us"
                        id="service_submenu"
                      >
                        {translate("About Us")}
                      </Link>
                      <ul
                        className="dropdown-menu "
                        aria-labelledby="service_submenu"
                      >
                        <li
                          className={pathname === "/about-us" ? "active" : ""}
                        >
                          <Link
                            className="nav-link "
                            to="/about-us"
                            id="courses_layout_submenu"
                          >
                            {translate("About Us")}
                          </Link>
                        </li>
                        <li className={pathname === "/gallery" ? "active" : ""}>
                          <Link
                            className="nav-link"
                            to="/gallery"
                            id="courses_details_submenu"
                          >
                            {translate("Gallery")}
                          </Link>
                        </li>
                      </ul>
                    </li>

                    <li className={`dropdown ${isActive && "active"}`}>
                      <Link className="nav-link" href="#" id="service_submenu">
                        {translate("Courses")}
                      </Link>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="service_submenu"
                      >
                        <li
                          className={`dropdown ${
                            pathname === "/english-in-uk" ||
                            pathname === "/english-in-usa"
                              ? "active"
                              : ""
                          }`}
                        >
                          <Link
                            className="nav-link"
                            to="/english-in-uk"
                            id="courses_layout_submenu"
                          >
                            {translate("English")}
                          </Link>
                          <ul
                            className="dropdown-menu"
                            aria-labelledby="courses_layout_submenu"
                          >
                            <li
                              className={
                                pathname === "/english-in-uk" ? "active" : ""
                              }
                            >
                              <Link to="/english-in-uk">
                                {translate("English UK")}
                              </Link>
                            </li>
                            <li
                              className={
                                pathname === "/english-in-usa" ? "active" : ""
                              }
                            >
                              <Link to="/english-in-usa">
                                {translate("English USA")}
                              </Link>
                            </li>
                          </ul>
                        </li>
                        <li
                          className={`dropdown ${
                            pathname === "/foundation-in-uk" ||
                            pathname === "/foundation-in-usa"
                              ? "active"
                              : ""
                          }`}
                        >
                          <Link
                            className="nav-link"
                            href="#"
                            id="courses_details_submenu"
                          >
                            {translate("Foundation")}
                          </Link>
                          <ul
                            className="dropdown-menu"
                            aria-labelledby="courses_details_submenu"
                          >
                            <li
                              className={
                                pathname === "/foundation-in-uk" ? "active" : ""
                              }
                            >
                              <Link to="/foundation-in-uk">
                                {translate("Foundation UK")}
                              </Link>
                            </li>
                            <li
                              className={
                                pathname === "/foundation-in-usa"
                                  ? "active"
                                  : ""
                              }
                            >
                              <Link to="/foundation-in-usa">
                                {translate("Foundation USA")}
                              </Link>
                            </li>
                          </ul>
                        </li>
                        <li
                          className={`dropdown ${
                            pathname === "/undergraduate-in-uk" ||
                            pathname === "/undergraduate-in-usa"
                              ? "active"
                              : ""
                          }`}
                        >
                          <Link
                            className="nav-link"
                            id="courses_details_submenu"
                          >
                            {translate("Undergraduate")}
                          </Link>
                          <ul
                            className="dropdown-menu"
                            aria-labelledby="courses_details_submenu"
                          >
                            <li
                              className={
                                pathname === "/undergraduate-in-uk"
                                  ? "active"
                                  : ""
                              }
                            >
                              <Link to="/undergraduate-in-uk">
                                {translate("Undergraduate UK")}
                              </Link>
                            </li>
                            <li
                              className={
                                pathname === "/undergraduate-in-usa"
                                  ? "active"
                                  : ""
                              }
                            >
                              <Link to="/undergraduate-in-usa">
                                {translate("Undergraduate USA")}
                              </Link>
                            </li>
                          </ul>
                        </li>
                        <li
                          className={`dropdown ${
                            pathname === "/master-in-uk" ||
                            pathname === "/master-in-usa"
                              ? "active"
                              : ""
                          }`}
                        >
                          <Link
                            className="nav-link"
                            href="#"
                            id="courses_details_submenu"
                          >
                            {translate("Master")}
                          </Link>
                          <ul
                            className="dropdown-menu"
                            aria-labelledby="courses_details_submenu"
                          >
                            <li
                              className={
                                pathname === "/master-in-uk" ? "active" : ""
                              }
                            >
                              <Link to="/master-in-uk">
                                {translate("Master UK")}
                              </Link>
                            </li>
                            <li
                              className={
                                pathname === "/master-in-usa" ? "active" : ""
                              }
                            >
                              <Link to="/master-in-usa">
                                {translate("Master USA")}
                              </Link>
                            </li>
                          </ul>
                        </li>
                        <li className={pathname === "/phd" ? "active" : ""}>
                          <Link
                            className="nav-link"
                            to="/phd"
                            id="courses_details_submenu"
                          >
                            {translate("PHD")}
                          </Link>
                        </li>
                        <li
                          className={
                            pathname === "/clinical-pharmacy" ? "active" : ""
                          }
                        >
                          <Link
                            className="nav-link"
                            to="/clinical-pharmacy"
                            id="courses_details_submenu"
                          >
                            {translate("Clinical Pharmacy")}
                          </Link>
                        </li>
                        <li
                          className={
                            pathname === "/anaesthesia" ? "active" : ""
                          }
                        >
                          <Link
                            className="nav-link"
                            to="/anaesthesia"
                            id="courses_details_submenu"
                          >
                            MSc Anaesthesia and Perioperative Medicine
                          </Link>
                        </li>
                      </ul>
                    </li>

                    <li
                      className={
                        pathname === "/news-and-events" ? "active" : ""
                      }
                    >
                      <Link
                        className="nav-link"
                        to="/news-and-events"
                        id="blog_submenu"
                      >
                        {translate("News & Event")}
                      </Link>
                    </li>
                    <li className={pathname === "/contact-us" ? "active" : ""}>
                      <Link className="nav-link" to="/contact-us">
                        {translate("Contact")}
                      </Link>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
            <div className="col col-lg-3 col-5">
              <ul className="header_btns_group unordered_list_end">
                <li>
                  <button
                    className="mobile_menu_btn"
                    type="button"
                    onClick={() => setopen((ps) => !ps)}
                  >
                    <i className="far fa-bars"></i>
                  </button>
                </li>
                <li>
                  {currentLang.value === "en" ? (
                    <button
                      className="btn border_dark"
                      onClick={(e) => {
                        onChangeLang("ar");
                        localStorage.setItem("i18nextLng", "ar");
                      }}
                    >
                      <span>
                        <small> عربى</small>
                        <small> عربى</small>
                      </span>
                    </button>
                  ) : (
                    <button
                      className="btn border_dark"
                      onClick={(e) => {
                        onChangeLang("en");
                        localStorage.setItem("i18nextLng", "en");
                      }}
                    >
                      <span>
                        <small>English</small>
                        <small>English</small>
                      </span>
                    </button>
                  )}
                </li>
                <li>
                  <button
                    className="btn btn_primary"
                    onClick={() => setOpenForm(true)}
                  >
                    <span>
                      <small>{translate("Apply Now")}</small>
                      <small>{translate("Apply Now")}</small>
                    </span>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
