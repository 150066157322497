import * as React from "react";
import { Link, useLocation } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

import Slide from "@mui/material/Slide";
import { Grid, IconButton } from "@mui/material";
import { HOST_API, emailregex } from "../config";
import SnackbarCustom from "./SnackbarCustom";
import useLocales from "../locales/useLocales";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function inputRestrict(e, removeListRegex) {
  const input = e.target;
  const start = input.selectionStart,
    end = input.selectionEnd;
  const initialLen = input.value.length;
  input.value = input.value.replace(removeListRegex, "");
  const lengthDifference = input.value.length - initialLen;
  if (lengthDifference) {
    const newStart = start + lengthDifference;
    const newEnd = end + lengthDifference;
    input.setSelectionRange(newStart, newEnd);
  }
}
export const restrict = {
  email(e) {
    //alphanumericdotampersand
    inputRestrict(e, /[^@a-zA-Z-_.\d]/g);
  },
  name(e) {
    inputRestrict(e, /[^a-zA-Z\u0600-\u06FF ]/g);
  },
  digits(e) {
    inputRestrict(e, /\D/g);
  },
  remarks(e) {
    inputRestrict(e, /[^-a-zA-Z(),&.+ \d\u0600-\u06FF]/g);
  },
};
export default function ApplyForm({ onClose, isSelectedCourse }) {
  const { pathname } = useLocation();
  const { translate,currentLang} = useLocales();
  const [value, setValue] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [openMsg, setOpenMsg] = React.useState({
    open: false,
    msg: "",
    type: "",
  });

  const isAnaesthesia =
    pathname === "/anaesthesia";

  const resetField = () => {
    for (let x in value) {
      value[x] = "";
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if(emailregex.test(value['email'])){
        setLoading(true);
        const res = await fetch(HOST_API + "applyNow", {
          method: "POST",
          body: JSON.stringify({...value,lang:currentLang?.label}),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        });
        const data = await res.json();
        if (!data.status) {
          throw new Error("An unexpected error occurred");
        }
        setOpenMsg({
          open: true,
          msg: "Thank for applying we will get back you soon",
          type: "success",
        });
        resetField();
      }
     else{
      setOpenMsg({ open: true, msg: "Please Enter a valid email id", type: "error" });
     }
    } catch (error) {
      console.log(error);
      setLoading(false);
      setOpenMsg({ open: true, msg: "Something went wrong", type: "error" });
    }
    setLoading(false);
  };
  const handleChange = (e) => {
    setValue((ps) => ({ ...ps, [e.target.name]: e.target.value }));
  };
  React.useEffect(() => {
    if (isSelectedCourse) {
      setValue((ps) => ({ ...ps, courseType: isSelectedCourse }));
    }
  }, []);
  return (
    <div>
      <SnackbarCustom
        open={openMsg.open}
        msg={openMsg.msg}
        type={openMsg.type}
        onClose={() => {
          setOpenMsg({ open: false, msg: "", type: "" });
        }}
      />
      <Dialog
        open={true}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="lg"
        fullWidth
        sx={{ p: 0 }}
      >
        {/* <DialogTitle justifyContent="end" display="flex" sx={{ p: 1 }}>
          {" "}
          <IconButton onClick={onClose} size="small">
            <i
              className="fas fa-times"
              style={{ fontSize: "18px", color: "black" }}
            ></i>
          </IconButton>
        </DialogTitle> */}
        <DialogContent sx={{ p: 0 }}>
          <Grid container spacing={0}>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                background: "url(assets/images/service/applyNow.png)",
                backgroundPosition: "center",
                backgroundSize: "cover",
                height: { xs: 400, sm: 400, md: "auto" },
              }}
            ></Grid>
            <Grid item xs={12} md={6}>
              <IconButton
                onClick={onClose}
                size="small"
                sx={{ float: "right" }}
              >
                <i
                  className="fas fa-times"
                  style={{ fontSize: "18px", color: "black" }}
                ></i>
              </IconButton>
              <div className="" style={{ padding: "30px" }}>
                <form action="https://formspree.io/f/xvoenere" method="post">
                  {isAnaesthesia
                    ? <h3 className="form_title">Request Information</h3>
                    : <h3 className="form_title">{translate('Apply Now')}</h3>
                  }
                  <div className="form_item">
                    <label
                      htmlFor="input_name"
                      className="input_title text-uppercase"
                    >
                      {translate('Name')}<span style={{color:'red'}}>*</span>
                    </label>
                    <input
                      id="input_name"
                      type="text"
                      name="name"
                      placeholder={translate('Enter Name')}
                      value={value["name"]}
                      onChange={handleChange}
                      onInput={restrict.name}
                      required
                      maxLength="100"
                    />
                  </div>
                  <div className="form_item">
                    <label
                      htmlFor="input_email"
                      className="input_title text-uppercase"
                    >
                      {translate('Email')}<span style={{color:'red'}}>*</span>
                    </label>
                    <input
                      id="input_email"
                      // type="email"
                      name="email"
                      onInput={restrict.email}
                      placeholder={translate('Enter Email')}
                      value={value["email"]}
                      onChange={handleChange}

                      required
                    />
                  </div>
                  <div className="form_item">
                    <label
                      htmlFor="input_number"
                      className="input_title text-uppercase"
                    >
                      {translate('Phone Number')}<span style={{color:'red'}}>*</span>
                    </label>
                    <input
                      id="input_number"
                      type="text"
                      name="number"
                      onInput={restrict.digits}
                      value={value["number"]}
                      onChange={handleChange}
                      placeholder={translate('Enter Number')}
                      required
                      maxLength="15"
                    />
                  </div>
                  <div className="form_item">
                    <label
                      htmlFor="input_course"
                      className="input_title text-uppercase"
                    >
                      {translate('Program Name')}
                    </label>
                    <select
                      name="courseType"
                      value={value["courseType"]}
                      onChange={handleChange}
                      
                      disabled={!!isSelectedCourse}
                    >
                      <option value="">{translate('Course name')}</option>
                      <option value="English UK">English UK</option>
                      <option value="English USA">English USA</option>
                      <option value="Foundation UK">Foundation UK</option>
                      <option value="Foundation USA">Foundation USA</option>
                      <option value="Undergraduate UK">Undergraduate UK</option>
                      <option value="Undergraduate USA">
                        Undergraduate UAS
                      </option>
                      <option value="Master UK">Master UK</option>
                      <option value="Master USA">Master USA</option>
                      <option value="PHD">PHD</option>
                      <option value="Clinical Pharmacy">Clinical Pharmacy</option>
                      <option value="MSc Anaesthesia and Perioperative Medicine">MSc Anaesthesia and Perioperative Medicine</option>
                    </select>
                  </div>
                  <div className="form_item">
                    <label
                      htmlFor="input_question"
                      className="input_title text-uppercase"
                    >
                      {translate('Message')}
                    </label>
                    <textarea
                      id="input_question"
                      name="message"
                      value={value["message"]}
                      onChange={handleChange}
                      onInput={restrict.remarks}
                      placeholder={translate("Enter Your Message")}
                      maxLength="200"
                    ></textarea>
                  </div>
                  <button
                    type="submit"
                    className="btn btn_dark w-100"
                    disabled={loading}
                  >
                    <span>
                      <small>{loading ? translate('Please Wait...'):translate('Send')}</small> <small>{loading ? translate('Please Wait...'):translate('Send')}</small>
                    </span>
                  </button>
                </form>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
