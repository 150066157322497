import { Suspense, lazy } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
import LoadingScreen from "../component/LoadingScreen";
import Home from "../pages/index";
import Faqs from "../pages/Faqs";
import TermsAndCondition from "../pages/TermsAndCondition";
import PrivacyPolicy from "../pages/PrivacyPolicy";



// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
};

const Gallery = Loadable(lazy(() => import("../pages/Gallery")));
const AboutUs = Loadable(lazy(() => import("../pages/AboutUs")));
const MeetTheTeam = Loadable(lazy(() => import("../pages/MeetTheTeam")));
const Phd = Loadable(lazy(() => import("../pages/Phd")));
const EnglishUk = Loadable(lazy(() => import("../pages/EnglishUk")));
const EnglishUsa = Loadable(lazy(() => import("../pages/EnglishUsa")));
const FoundationUk = Loadable(lazy(() => import("../pages/FoundationUk")));
const FoundationUsa = Loadable(lazy(() => import("../pages/FoundationUsa")));
const AllCourses = Loadable(lazy(() => import("../pages/AllCourses")));

const UnderGraduateUk = Loadable(
  lazy(() => import("../pages/UnderGraduateUk"))
);
const UnderGraduateUsa = Loadable(
  lazy(() => import("../pages/UnderGraduateUsa"))
);
const MasterUk = Loadable(lazy(() => import("../pages/MasterUk")));
const MasterUsa = Loadable(lazy(() => import("../pages/MasterUsa")));
const Blogs = Loadable(lazy(() => import("../pages/Blog")));
const Pharmacy = Loadable(lazy(() => import("../pages/ClinicalPharmacy")));
const Anaesthesia = Loadable(lazy(() => import("../pages/Anaesthesia")));
const BlogDetails = Loadable(lazy(() => import("../pages/BlogDetails")));
const Contact = Loadable(lazy(() => import("../pages/Contact")));
const Login = Loadable(lazy(() => import("../pages/admin/Login")));
//admin
const DashboardLayout = Loadable(
  lazy(() => import("../pages/admin/DashboardLayout"))
);
const Dashboard = Loadable(lazy(() => import("../pages/admin/Dashboard")));
const GalleryInfo = Loadable(lazy(() => import("../pages/admin/gallery")));
const EventDetails = Loadable(lazy(() => import("../pages/admin/news-event")));
const CoursesLead = Loadable(lazy(() => import("../pages/admin/leads/CoursesLead")));
const ContactLeads = Loadable(lazy(() => import("../pages/admin/leads/ContactLeads")));

export default function Router() {
  const user = JSON.parse(sessionStorage.getItem("User"));
  return useRoutes([
    { path: "/", element: <Home /> },
    { path: "/about-us", element: <AboutUs /> },
    { path: "/gallery", element: <Gallery /> },
    { path: "/meet-the-team", element: <MeetTheTeam /> },
    { path: "/english-in-uk", element: <EnglishUk /> },
    { path: "/english-in-usa", element: <EnglishUsa /> },
    { path: "/foundation-in-uk", element: <FoundationUk /> },
    { path: "/foundation-in-usa", element: <FoundationUsa /> },

    { path: "/undergraduate-in-uk", element: <UnderGraduateUk /> },
    { path: "/undergraduate-in-usa", element: <UnderGraduateUsa /> },

    { path: "/master-in-uk", element: <MasterUk /> },
    { path: "/master-in-usa", element: <MasterUsa /> },
    { path: "/clinical-pharmacy", element: <Pharmacy /> },
    { path: "/anaesthesia", element: <Anaesthesia /> },

    { path: "/all-courses", element: <AllCourses /> },
    { path: "/contact-us", element: <Contact /> },
    { path: "/phd", element: <Phd /> },
    { path: "/faqs", element: <Faqs /> },
    { path: "/terms-and-condition", element: <TermsAndCondition /> },
    { path: "/privacy-and-policy", element: <PrivacyPolicy /> },
    { path: "/news-and-events", element: <Blogs /> },
    { path: "/article-detail", element: <BlogDetails /> },
    { path: "/login", element: <Login /> },
    { path: "/404", element: <h1>404</h1> },
    {
      path: "/admin",
      element: user ? <DashboardLayout /> : <Navigate to="/login" />,
      children: [
        { element: <Dashboard />, index: true },
        { path: "gallery-detail", element: <GalleryInfo /> },
        { path: "news-event-detail", element: <EventDetails /> },
        { path: "courses-lead", element: <CoursesLead /> },
        { path: "contact-lead", element: <ContactLeads /> },
      ],
    },

    { path: "/*", element: <Navigate to="/404" replace /> },
  ]);
}
